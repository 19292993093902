import React from 'react'
import FeatureList from '../components/pages/home/feature-list'
import { Link, graphql } from 'gatsby'
import { Container, Col, Row } from 'reactstrap'
import Map from '../components/pages/common/map'
import Header from '../components/pages/common/header'
import Layout from '../components/layout'
import Alert from '../components/alert/alert'

import Helmet from 'react-helmet'

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Home | Whickham Thorns Outdoor Activity Centre</title>
        <meta
          name="description"
          content="Whickham Thorns is an activity centre based in Gateshead, UK. Our adventurous actvities include climbing, skiing, snow tubing and much more!"
        />
      </Helmet>
      {/* <Alert color="primary" fade={false} toggle={false}>
        Due to the ongoing situation with COVID-19, we are temporarily suspending activity provision at Whickham Thorns. We will reopen again soon with even more adventurous activities on your doorstep. Thank you for you continued support at this time. We wish all of our customers well in these challenging times.
      </Alert> */}
      <Header
        headerImage={data.headerImage}
        title="Whickham Thorns Outdoor Activity Centre"
        subtitle="Adventure Experiences to Remember"
        height="35rem"
      >
        <Link 
          className="btn btn-primary btn-lg mt-4" 
          to="/book"
        >
          Book Now
        </Link>
      </Header>
      <Container>
        <FeatureList features={[
          {
            heading: 'Groups & Schools',
            icon: {
              prefix: 'fal',
              icon: 'compass',
            },
          },
          {
            heading: 'Families',
            icon: {
              prefix: 'fal',
              icon: 'users',
            }
          },
          {
            heading: 'Parties',
            icon: {
              prefix: 'fal',
              icon: 'birthday-cake',
            }
          }
        ]} />
      </Container>
      <hr />
      <Container className="my-5">
        <h2 className="text-center">Our Activities</h2>

        <Row>
          <Col md={{ size: 4, offset: 3 }}>
            <ul>
              <li>Climbing</li>
              <li>Skiing</li>
              <li>Snow Tubing</li>
              <li>Swiss Bobs - Sledging</li>
              <li>Forest Schools</li>
              <li>Bush Craft</li>
              <li>High ropes - coming soon</li>
            </ul>
          </Col>
          <Col md={{ size: 4 }}>
            <ul>
              <li>Mountain Biking</li>
              <li>Team Challenges</li>
              <li>Orienteering</li>
              <li>Bouldering</li>
              <li>Assault Course</li>
              <li>Night-line</li>
            </ul>
          </Col>
        </Row>

        <p className="text-center mt-3">
          If you are interested in booking one of these activities or would like more information, please <Link to="contact">contact us</Link>.
        </p>
      </Container>
      <Map key="map" />
    </Layout>
  )
}

export const query = graphql`
  query HomeQuery {
    headerImage: file(relativePath: { eq: "climbing.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 70)
      }
    }
  }
`

export default IndexPage
