import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import Feature from './feature'

const Container = styled(Row)`
  padding: 2rem 0;
`

const FeatureList = ({ features }) => (
  <Container>
    {features.map((feature, index) => (
      <Col sm="12" md="4" key={index}>
        <Feature
          heading={feature.heading}
          description={feature.description}
          icon={feature.icon}
          link={feature.link}
        />
      </Col>
    ))}
  </Container>
)

FeatureList.propTypes = {
  features: PropTypes.arrayOf(PropTypes.shape({
    heading: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      prefix: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    }).isRequired,
  })).isRequired,
}

export default FeatureList