import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'

const FeatureContainer = styled.div`
  padding: 0 0.5rem;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #006ddf;
  border-radius: 100%;
  height: 8rem;
  margin: 1rem auto;
  padding: 0.5rem;
  width: 8rem;
`

const Icon = styled(FontAwesomeIcon)`
  color: #fff;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 100% !important;
  padding: 15%;
`

const Heading = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1rem auto;
  text-align: center;
  color: #0b1d51;
`

const Description = styled.p`
  text-align: center;
`

const Button = styled(Link).attrs({
  className: 'btn btn-primary'
})`

`

const Feature = ({ heading, icon, description, link }) => (
  <FeatureContainer>
    <IconWrapper>
      <Icon icon={[icon.prefix, icon.icon]} />
    </IconWrapper>

    <Heading>{heading}</Heading>

    <Description>{description}</Description>

    {link && (
      <p style={{ textAlign: 'center' }}>
        <Button to={link}>Find out more</Button>
      </p>
    )}
  </FeatureContainer>
)

Feature.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.shape({
    prefix: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  link: PropTypes.string,
}

export default Feature
