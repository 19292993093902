import React from 'react'
import styled from 'styled-components'
import { UncontrolledAlert as BSAlert } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Icon = styled(FontAwesomeIcon)`
  font-size: 2.5rem !important;
`

const Alert = ({ children, ...props }) => (
  <BSAlert {...props}>
    <div className="d-flex">
      <div className="mr-3">
        <Icon icon={['fal', 'info-circle']} />
      </div>
      <div>
        {children}
      </div>
    </div>
  </BSAlert>
)


export default Alert